import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import MultipartUploader from './MultipartUploader';
import {errorHandler} from '../errors';
import {getOutlet, useOutlet} from 'reconnect.js';
import Button from '../Widgets/Button';
import Row from '../Widgets/Row';
import {FileUpload} from '@styled-icons/material-outlined/FileUpload';
import {Attachment} from '@styled-icons/icomoon/Attachment';
import {Spinner2} from '@styled-icons/evil/Spinner2';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
const appConfig = require('../data.json');

export default function UploadFileButton({disabled, file, onUpload, onUpdate}) {
  const [newFile, setNewFile] = useState();
  const [loading, setLoading] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const uploader = useRef();
  const url = useRef('');
  const [actions] = useOutlet('actions');

  useEffect(() => {
    uploader.current = new MultipartUploader({
      host: appConfig.endpoint.revStorageHost,
      debug: appConfig.debug,
      onProgress: (percentage) => setPercentage(percentage),
      onStart: () => {},
      onComplete: ({url: _url}) => {
        // let uri = encodeURI(url);
        url.current = _url;
      },
    });

    return () => uploader.current.close();
  }, []);

  const upload = useCallback(async () => {
    setLoading(true);

    try {
      // step 1: get JWT token
      await actions.getMicroServiceToken();
      let token = getOutlet('jwtToken').getValue();

      console.log('token', token);

      uploader.current.setToken(token);

      // step 2 add file to uppy

      let result = await uploader.current.upload(newFile);
      console.log('RETURN FROM UPLOAD', result);

      if (result) {
        if (result.successful) {
          Ant.message.success('上傳成功');
        } else {
          console.warn('Errors: ', result.failed);
          Ant.message.error('上傳失敗');
        }

        // step 4: update order_item attachment
        await onUpload({url: url.current});

        onUpdate();
      }
    } catch (err) {
      errorHandler(err);

      if (err.status === 403) {
        await actions.getMicroServiceToken();
      }
    }

    uploader.current.reset();
    setLoading(false);
    setNewFile(null);
  }, [newFile]);

  return (
    <div>
      <Row style={{marginBottom: 5}}>
        <FileInput disabled={disabled}>
          <input
            disabled={disabled}
            // onClick={(e) => e.stopPropagation()}
            type="file"
            onChange={(e) => setNewFile(e.target.files[0])}
          />
          <span>選擇檔案</span>
        </FileInput>
        <Button
          type="primary"
          color={appConfig.colors.sub}
          size="small"
          disabled={disabled}
          onClick={(e) => {
            upload();
          }}>
          <FileUpload size={18} color="#fff" style={{marginRight: 5}} />
          上傳
        </Button>
      </Row>

      {newFile ? (
        <Row>
          <Ant.Spin
            spinning={loading}
            indicator={<LoadingOutlined style={{fontSize: 22}} />}
            style={{marginRight: 5}}
          />
          <Attachment color="#aaa" size={16} />
          <div style={{marginLeft: 5, color: '#aaa', wordBreak: 'break-word'}}>
            {newFile.name}
          </div>
        </Row>
      ) : file ? (
        <div style={{display: 'inline-block'}}>
          <Ant.Spin
            spinning={loading}
            indicator={<LoadingOutlined style={{fontSize: 18}} />}
            style={{marginRight: 5}}
          />
          <Attachment color={appConfig.colors.link} size={16} />
          <a
            href={file.link}
            style={{marginLeft: 5, wordBreak: 'break-word'}}
            target="_blank"
            rel="noreferrer">
            {file.name}
          </a>
        </div>
      ) : null}
    </div>
  );
}

const FileInput = styled.label`
  color: white;
  background-color: ${appConfig.colors.sub};
  height: 30px;
  padding: 0 15px;
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  margin-right: 5px;
  & > input {
    position: absolute;
    display: none;
  }
  & > span {
    line-height: 1.9rem;
  }

  &:hover {
    opacity: 0.7;
  }
`;
